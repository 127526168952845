import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
//import Img from "gatsby-image"
//import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    //console.log(post)
    //console.log(next);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article className="cardPost card">
          <header>
            <h1 className="entry-title">
              {post.frontmatter.title}
            </h1>

            <div className="entry-date">
              {post.frontmatter.date}
            </div>

            <div className="addthis_inline_share_toolbox"></div>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <div>
            {post.frontmatter.tags !== null ?
              post.frontmatter.tags.map(tag => (
                <Link to={`/tags/${SlugLink(tag)}`} style={{ marginLeft: 5, color: '#FFF' }} key={tag}><Button variant="dark" style={{margin: 2}}>{tag}</Button></Link>
              ))
              :
              null
            }
          </div>
          <footer>
            {/* <Bio /> */}
          </footer>
        </article>

        <Row className="related-before">
          <Col xs={12} md={6}>
            {previous && (
              <div className="cardPost1">
                <Link to={previous.fields.slug} rel="prev">
                  <div style={{
                    backgroundImage: `url(${previous.frontmatter.cover.childImageSharp.fluid.src})`,
                  }} className="related-img"
                  >
                    {/* <Image height={75} src={previous.frontmatter.cover.childImageSharp.fluid.src} fluid rounded /> */}
                    {/* <img height={75} src={previous.frontmatter.cover.childImageSharp.fluid.src} /> */}
                  </div>
                  <div className="related-title">
                    <span className="spantitle">{previous.frontmatter.title}
                    </span>
                  </div>
                </Link>
              </div>
            )}

          </Col>
          <Col xs={12} md={6}>
            {next && (
              <div className="cardPost1">
                <Link to={next.fields.slug} rel="next">
                  <div style={{
                    backgroundImage: `url(${next.frontmatter.cover.childImageSharp.fluid.src})`,
                  }} className="related-img">
                  </div>
                  <div className="related-title">
                    <span className="spantitle">{next.frontmatter.title}</span>
                  </div>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
